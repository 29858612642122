<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">

        <!-- image -->
        <b-img
          fluid
          :src="imgUrl"
          alt="No Data"
        />
        <h2 class="my-1 display-4 text-center">
      
            {{ getCookie("lang") == "en"
            ? 'There is no data'
            : 'لا توجد بيانات' }}
        </h2>
      </div>

    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {  BImg } from 'bootstrap-vue'

export default {
  components: {
    
    BImg,
  },
  data() {
    return {
      imgUrl: require('@/assets/images/pages/no-data.svg'),

    }
  },
    methods: {
    getCookie(name) {
      const nameEQ = `${name}=`
      const ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') c = c.substring(1, c.length)
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
      }
      return null
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
